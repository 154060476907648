import React, {useContext, useEffect, useState} from "react";
import Headroom from "react-headroom";
import StyleContext from "../../contexts/StyleContext";
import {
  achievementSection,
  blogSection,
  greeting,
  openSource,
  skillsSection,
  talkSection,
  workExperiences
} from "../../portfolio";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "./Header.scss";
function Header() {
  const {isDark} = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewOpenSource = openSource.display;
  const viewSkills = skillsSection.display;
  const viewAchievement = achievementSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;

  const [visits, setVisits] = useState(null);
  const BIN_ID = "6742f261ad19ca34f8cf697c";
  const API_KEY =
    "$2a$10$fzkDdkhpcOPBHrwz2pLBTOND/sXUcwVAP9P0FmUJ8zUGu/WRC4aom";
  const fetchVisits = async () => {
    try {
      const response = await fetch(`https://api.jsonbin.io/v3/b/${BIN_ID}`, {
        method: "GET",
        headers: {
          "X-Master-Key": API_KEY
        }
      });

      if (!response.ok) {
        throw new Error("Failed to fetch visits");
      }

      const data = await response.json();
      return data.record.visits; // Return the fetched visits
    } catch (error) {
      return null;
    }
  };

  // Increment visit count
  const incrementVisits = async currentVisits => {
    try {
      const response = await fetch(`https://api.jsonbin.io/v3/b/${BIN_ID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Master-Key": API_KEY
        },
        body: JSON.stringify({visits: currentVisits + 1})
      });

      if (!response.ok) {
        return;
      }

      const data = await response.json();
      setVisits(data.record.visits); // Update state with the new value
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const updateVisitCount = async () => {
      const currentVisits = await fetchVisits(); // Fetch visits
      if (currentVisits !== null) {
        await incrementVisits(currentVisits); // Increment and update
      }
    };

    updateVisitCount();
  }, []);

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/" className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{color: "white"}}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewSkills && (
            <li>
              <a href="#skills">Skills</a>
            </li>
          )}
          {viewExperience && (
            <li>
              <a href="#experience">Work Experiences</a>
            </li>
          )}
          {viewOpenSource && (
            <li>
              <a href="#opensource">Open Source</a>
            </li>
          )}
          {viewAchievement && (
            <li>
              <a href="#achievements">Achievements</a>
            </li>
          )}
          {viewBlog && (
            <li>
              <a href="#blogs">Blogs</a>
            </li>
          )}
          {viewTalks && (
            <li>
              <a href="#talks">Talks</a>
            </li>
          )}
          <li>
            <a href="#contact">Contact Me</a>
          </li>
          <li>
            <a>
              <ToggleSwitch />
            </a>
          </li>

          <li>
            <>
              {visits ? (
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  {" "}
                  <img
                    style={{height: "3rem"}}
                    src={require("../../assets/images/android-chrome-512x512.png")}
                    alt="Naveen Logo"
                  />{" "}
                  <p>Impressions: </p>
                  <p className="count-container"> {visits} </p>
                </div>
              ) : (
                <img
                  style={{height: "3rem"}}
                  src={require("../../assets/images/android-chrome-512x512.png")}
                  alt="Naveen Logo"
                />
              )}
            </>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;
